import React from "react";
import { GlobalStyles } from "twin.macro";

const NotFoundPage = () => (
  <>
    <GlobalStyles />
    <div>Error: 404</div>
  </>
);

export default NotFoundPage;
